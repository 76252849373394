<template>
  <label class="container">
    <input
      type="checkbox"
      :checked="isChecked"
      @click="emit('on-select', identifier, value, text, strategy)"
    />
    <span class="checkmark">
      <div class="checked-indicator" />
      <div class="placeholder"></div>
    </span>
    <span>
      {{ text }}
    </span>
  </label>
</template>

<script lang="ts" setup>
import { Filter } from '@/lib/types/filters';

// TODO: Duplicated with FiltersRadio

defineProps<{
  text: string;
  identifier: string;
  value: string;
  strategy?: Filter['strategy'];
}>();

const emit =
  defineEmits<
    (
      event: 'on-select',
      identifier: string,
      value: string,
      text: string,
      strategy?: Filter['strategy']
    ) => void
  >();

const attrs = useAttrs();
const isChecked = computed(() => !!attrs['is-checked-attr']);
</script>

<style lang="scss" scoped>
// TODO: Refaire après le passage sur Vue 3

@use '$/colors.scss';
@use '$/border-radius.scss';

.container {
  cursor: pointer;
  user-select: none;

  display: flex;
  gap: 12px;
  align-items: center;

  input {
    cursor: pointer;

    position: absolute;

    width: 0;
    height: 0;

    opacity: 0;

    &:checked ~ .checkmark {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: white;
      border: solid 1px colors.$green;

      .checked-indicator {
        // Old: size: 16px and radius: 4px
        width: 100%;
        height: 100%;
        background-color: colors.$green;
        transition: 0.2s;
      }

      .placeholder {
        display: none;
      }
    }
  }

  .checkmark {
    width: 20px;
    height: 20px;
    border: solid 1px colors.$black;
    border-radius: border-radius.$small;

    &::after {
      position: absolute;
      top: 5px;
      left: 9px;

      display: none;

      width: 18px;
      height: 18px;

      background-color: white;
      border: solid 1px colors.$green;
      border-radius: border-radius.$small;
    }

    .placeholder {
      width: 20px;
      height: 20px;
    }
  }
}

@media (width <= 768px) {
  .container {
    input {
      &:checked ~ .checkmark {
        min-width: 20px;
        min-height: 20px;

        .checked-indicator {
          // Old border radius
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }
    }

    .checkmark {
      // Old: 24px
      width: 20px;
      height: 20px;
      border-radius: 4px;

      &::after {
        top: 4px;
        left: 7px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
